<template>
  <div>
    <!-- 表单 -->
    <div class="form">
      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label">
          <span class="iconfont icon-required"></span>
          <span>姓名</span>
        </div>
        <input type="text" v-model="formData.name" />
      </div>

      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label">
          <span class="iconfont icon-required"></span>
          <span>联系电话</span>
        </div>
        <input type="text" v-model="formData.phoneNumber" />
      </div>

      <!-- 表单项 -->
      <div v-if="!isMember" class="form-item flex-column-default-start">
        <div class="label">
          <span class="iconfont icon-required"></span>
          <span>所属单位</span>
        </div>
        <input type="text" v-model="formData.companyName" />
      </div>
    </div>

    <!-- （取消）报名按钮 -->
    <div class="button-one" @click="handleSignup">立即报名</div>
  </div>
</template>

<script>
import { Toast } from 'mint-ui'

export default {
  name: 'ActivitySignup',

  data () {
    return {
      activityID: '',
      canUpdate: false,
      isMember: false,
      formData: {
        name: '',
        phoneNumber: '',
        companyName: ''
      }
    }
  },

  created () {
    this.activityID = this.$route.query.id
    const userInfo = this.$store.state.userInfo
    if (userInfo) {
      this.isMember = userInfo.isMember
      this.formData.name = userInfo.name
      this.formData.phoneNumber = userInfo.phoneNumber
    }
  },

  methods: {
    // 报名
    handleSignup () {
      const params = this.formData
      if (this.formData.name === '') {
        Toast('姓名不能为空')
        return
      }
      if (this.formData.phoneNumber === '') {
        Toast('联系电话不能为空')
        return
      }
      if (!this.isMember && this.formData.companyName === '') {
        Toast('所属单位不能为空')
        return
      }
      params.id = this.activityID

      this.api.signupActivity(params).then(res => {
        Toast(res.msg)
        setTimeout(() => {
          this.$router.back()
        }, 500)
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.handleSignup() })
      })
    }
  }
}
</script>

<style scoped>
</style>
